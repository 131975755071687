import React from 'react'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SectionHeader from '../components/section-header';
import Row from '../components/row';

class ResourcesPage extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    const headerData = {
      title: 'Resources'
    }

    const familyResourceData = [
      {
        resourceName: 'The Herren Project',
        resourceDesc: 'Webinars and materials for families affected by addiction.',
        resourceLink: 'https://herrenproject.org/family-support-resources/'
      },
      {
        resourceName: 'The Herren Project',
        resourceDesc: 'Online family support group meetings.',
        resourceLink: 'https://herrenproject.org/online-support-groups/'
      },

      {
        resourceName: 'Partnership for Drug-Free Kids',
        resourceDesc: 'Parents Resources and Helpline 1-855-378-4373 (Monday-Friday 10am-6pm)',
        resourceLink: 'https://www.drugfree.org'
      },
      {
        resourceName: 'Al-Anon',
        resourceDesc: 'Place where individuals affected by someone else’s alcoholism share and provide hope for each other.',
        resourceLink: 'https://www.al-anon.org'
      },
      {
        resourceName: 'Family Resource Center',
        resourceDesc: 'Empowering families to understand and address a child’s substance use.',
        resourceLink: 'http://www.familyresourcectr.org'
      },
      {
        resourceName: 'Learn to Cope',
        resourceDesc: 'Education, resources, peer support and hope for parents and family members coping with a loved one addicted to opiates or other drugs.',
        resourceLink: 'https://www.learn2cope.org'
      },
      {
        resourceName: 'NIDA',
        resourceDesc: 'Facts for parents on teen drug use.',
        resourceLink: 'https://www.teens.drugabuse.gov/parents'
      },
      {
        resourceName: 'Families Anonymous',
        resourceDesc: 'For relatives and friends concerned about the use of drugs or related behavioral problems.',
        resourceLink: 'https://www.familiesanonymous.org'
      },
    ]

    const helpResourceData = [
      {
        resourceName: 'Herren Project',
        resourceDesc: 'Call Toll Free: 844-543-8555 (9:00am – 4:00pm)',
        resourceLink: 'https://herrenproject.org/individual-recovery-navigation/'
      },
      {
        resourceName: 'SAMHSA National Helpline',
        resourceDesc: 'Treatment Referral Service 24/7, 365 days/yr - 1-800-662-HELP (4357) TTY: 1-800-487-4889',
        resourceLink: 'https://www.samhsa.gov/find-help/national-helpline'
      },
    ]

    const meetingResourceData = [
      {
        resourceName: 'Herren Project',
        resourceDesc: 'Nightly online recovery meetings 7:30pm ET',
        resourceLink: 'https://herrenproject.org/recovery-meeting/'
      },
      {
        resourceName: 'Narcotics Anonymous',
        resourceDesc: 'Find a meeting, access literature and get support.',
        resourceLink: 'https://www.na.org'
      },
      {
        resourceName: 'Alcoholics Anonymous',
        resourceDesc: 'Find a meeting, access daily reflection and get support.',
        resourceLink: 'https://www.aa.org'
      },
    ]

    const overdoseResourceData = [
      {
        resourceName: 'Get Naloxone Now',
        resourceDesc: 'Evidence-based education, training and resources about opioid-associated overdose and naloxone.',
        resourceLink: 'https://www.getnaloxonenow.org'
      },
      {
        resourceName: 'The Chris Atwood Foundation',
        resourceDesc: 'Provides training and free naloxone.',
        resourceLink: 'https://www.thecaf.org/orders'
      },
      {
        resourceName: 'SAMHSA Overdose Prevention Toolkit',
        resourceDesc: 'This toolkit offers strategies for developing practices and policies to help prevent opioid-related overdoses and deaths.',
        resourceLink: 'https://store.samhsa.gov/sites/default/files/d7/priv/sma18-4742.pdf'
      },
    ]

    const stigmaResourceData = [
      {
        resourceName: 'Addiction and Stigma – How Stigma Hurts',
        resourceDesc: 'Definition and overview of addiction and stigma.',
        resourceLink: 'https://drugabuse.com/addiction/stigma/'
      },
      {
        resourceName: 'Greater Awareness Can Mitigate the Risk of Addiction',
        resourceDesc: 'Overview of strategies to reduce the risk of addiction.',
        resourceLink: 'https://addictions.iu.edu/understanding-crisis/preventing-addiction.html'
      },
    ]

    const comprehensiveResourceData = [
      {
        resourceName: 'Recovery.org',
        resourceDesc: 'Resources for those seeking recovery.',
        resourceLink: 'http://recovery.org/'
      },
      {
        resourceName: 'Thefix.com',
        resourceDesc: 'Support for sober living.',
        resourceLink: 'http://thefix.com/'
      },
      {
        resourceName: 'Shatterproof.org',
        resourceDesc: 'Information and statistics on addiction, treatment, and political action.',
        resourceLink: 'http://shatterproof.org/'
      },
    ]


    function ResourceEl(props) {
      const resource = props.resource
      return (
          <div style={{width: '500px', margin: '1% 5%', textAlign: 'center'}}>
          <a href={resource.resourceLink}><h4>{resource.resourceName}</h4></a>
          <p style={{textAlign: 'center', margin: '5px 0'}}>{resource.resourceDesc}</p>
          <a href={resource.resourceLink} target='_new'>Learn More</a>
          </div>
      )
    }

    return (
      <Layout location={this.props.location}>
          <Helmet title={siteTitle} />
          <SectionHeader data={headerData} />
          <h3>Comprehensive Resources</h3>
          <Row>

          {comprehensiveResourceData.map((resource,i) => <ResourceEl resource={resource} key={'a' + i}/>)}
          </Row>
          <hr style={{marginTop: '3%', marginBottom: '3%'}} />
          <h3>Stigma and Awareness</h3>
          <Row>
          {stigmaResourceData.map((resource,i) => <ResourceEl resource={resource} key={'b' + i}/>)}
          </Row>
          <hr style={{marginTop: '3%', marginBottom: '3%'}} />
          <h3>Resources for Families</h3>
          <Row>

          {familyResourceData.map((resource,i) => <ResourceEl resource={resource} key={'c' + i}/>)}
          </Row>
          <hr style={{marginTop: '3%', marginBottom: '3%'}} />
          <h3>Find Help & Treatment</h3>
          <Row>
          {helpResourceData.map((resource,i) => <ResourceEl resource={resource} key={'d' + i}/>)}
          </Row>
          <hr style={{marginTop: '3%', marginBottom: '3%'}} />
          <h3>Find a Meeting</h3>
          <Row>
          {meetingResourceData.map((resource,i) => <ResourceEl resource={resource} key={'e' + i}/>)}
          </Row>
          <hr style={{marginTop: '3%', marginBottom: '3%'}} />
          <h3>Preventing Overdose</h3>
          <Row>
          {overdoseResourceData.map((resource,i) => <ResourceEl resource={resource} key={'f' + i}/>)}
          </Row>
          <div style={{height: '50px'}}></div>


      </Layout>
    )
  }
}

export default ResourcesPage
